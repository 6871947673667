import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticatedGuard } from './core/guards/authenticated.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(mod => mod.HomeModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'sign-in',
    loadChildren: () => import('./authentication/sign-in/sign-in.module').then(mod => mod.SignInModule),
    data: { title: 'Log in to your CAPTR account' }
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./authentication/sign-up/sign-up.module').then(mod => mod.SignUpModule),
    data: { title: 'Create an account' }
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./authentication/forgot-password/forgot-password.module').then(mod => mod.ForgotPasswordModule),
    data: { title: 'Reset your password' }
  },
  {
    path: 'set-password',
    loadChildren: () => import('./authentication/set-password/set-password.module').then(mod => mod.SetPasswordModule),
    data: { title: 'Set password' }
  },
  {
    path: 'launch',
    loadChildren: () => import('./authentication/splash-screen/splash-screen.module').then(mod => mod.SplashScreenModule),
    data: { title: 'Introduction Video' }
  },
  {
    path: '**',
    redirectTo: 'content-unavailable',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
