export const ApplicationConstant = {
    ApplicationID : localStorage.getItem('ApplicationID'),
    clientId : localStorage.getItem('clientId'),
    organizationId: 4,
    DEFAULT_DATE_FORMAT: 'YYYY-MM-DD',
    KWAppID: 5,
    DEV_ENV:'DEV',
    TEST_ENV:'TEST',
    PROD_ENV:'PROD'
}
