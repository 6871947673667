import { PipeTransform, Pipe, Injectable } from '@angular/core';

@Pipe({
	name: 'label'
})
@Injectable()
export class LabelPipe implements PipeTransform {

	transform(string) {
		let output: String = "";
		const len = string.length;
		let char: String;

		for (let i = 0; i < len; i++) {
			char = string.charAt(i);

			if (i === 0) {
				output += char.toUpperCase();
			}
			else if (char !== char.toLowerCase() && char === char.toUpperCase()) {
				output += " " + char.toLowerCase();
			}
			else if (char === "-" || char === "_") {
				output += " ";
			}
			else {
				output += "" + char.toLowerCase();
			}
		}

		return output;
	}
}




