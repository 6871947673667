import {Pipe, PipeTransform, Injectable} from '@angular/core';
@Pipe({
    name: 'hours'
})
@Injectable()
export class HoursPipe implements PipeTransform {
    transform(items: any, field: string, value: string): any {
        if (!items) {
            return "";
        }

        let regexPattern = /^-?[0-9]+$/;
        if( regexPattern.test(items)) {
            return items + " hours";
        }else{
            let filteredItems = items.toString().split('.')[1].length > 1 ? items :items.toString() + '0';
            let arr = filteredItems.split('.');
            return `${arr[0]} hours ${arr[1]} minutes`;
            
        }
    }
}
