import { ApplicationConstant } from "src/app/shared/constants/app-constant";

export const environment = {
  production: true,
  api: 'https://devservices.sequation.net',
  enableCtrl: true,
  // azureBlobStorage: 'https://sequation-dev-cdn-v2.sequation.net',
  azureCDNStorageUrl: 'https://sequation-dev-cdn-v2.sequation.net',
  azureBlobStorage: 'https://sequationdevsav2.blob.core.windows.net',
  
  env:ApplicationConstant.DEV_ENV
};
