import { Component, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SwUpdate } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AuthenticationService } from './authentication/authentication.service';
import { Url } from './shared/constants/url-constants';

@Component({
  selector: 'vc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  logo: string;
  clientDetails;

  constructor(
    private readonly title: Title,
    private swUpdate: SwUpdate,
    private readonly authenticationService: AuthenticationService,
    private readonly renderer: Renderer2
  ) {
    this.title.setTitle('Sign In');
  }

  ngOnInit() {
    if (!environment.enableCtrl) {
      this.disableControls();
    }

    if (this.swUpdate.isEnabled) {
      console.log("Serviceworker update enabled ");
      this.swUpdate.activated.subscribe((upd) => {
        console.info("received update"+upd+" and activated");
        window.location.reload();
      });
      this.swUpdate.available.subscribe((upd) => {
        console.log("New version avilable "+upd);
        
        this.swUpdate.activateUpdate();
      }, (error) => {
        console.error(error);
      });

      this.swUpdate.checkForUpdate().then(() => {
        console.log("Got new Version");
      }).catch((error) => {
        console.error('Could not check for app updates', error);
      });
    }
      this.authenticationService.getClientDetails().subscribe(data => {
        this.clientDetails = data;
        localStorage.setItem('clientId', this.clientDetails.id);
        localStorage.setItem('logoPath', this.clientDetails.logoPath);
        localStorage.setItem('ApplicationID',this.clientDetails.appId);
        localStorage.setItem('moodleUrl',this.clientDetails.moodleUrl)
        localStorage.setItem('isSelfRegisterAllowed',this.clientDetails.isSelfRegisterAllowed)
        document.documentElement.style.setProperty('--primary-color', this.clientDetails.primaryAppColour);
        document.documentElement.style.setProperty('--secondary-color', this.clientDetails.secondaryAppColour);
      });

      this.authenticationService.currentLogo.subscribe(logo => {    
        this.logo = logo +  localStorage.getItem('logoPath');
    });
    this.applyUserway();
  }

  disableControls() {

    function keyPressCheck(event) {
      if (event.keyCode === 123) {
        return false;
      }
    }

    document.oncontextmenu = function () {
      return false;
    }

    function disableCtrlKeyCombination(e) {
      //list all CTRL + key combinations you want to disable
      const forbiddenKeys = new Array('a', 'n', 'c', 'x', 'v', 'j', 'w');
      let key, isCtrl;

      if (window.event) {
        key = (<any>window.event).keyCode; //IE
        if ((<any>window.event).ctrlKey) {
          isCtrl = true;
        } else {
          isCtrl = false;
        }
      } else {
        key = e.which; //firefox
        if (e.ctrlKey) {
          isCtrl = true;
        } else {
          isCtrl = false;
        }
      }
      //if ctrl is pressed check if other key is in forbidenKeys array
      if (isCtrl) {
        for (let i = 0; i < forbiddenKeys.length; i++) {
          //case-insensitive comparation
          if (forbiddenKeys[i].toLowerCase() === String.fromCharCode(key).toLowerCase()) {
            return false;
          }
        }
      }
      return true;
    }
  }

  applyUserway() {
    let url = window.location.href;
    if(url.includes('test.captr')){
      this.renderer.addClass(document.body, 'userway');
    } else if (url.includes('mnprt') ){
      this.renderer.addClass(document.body, 'aladdin-app');
    }
  }

}
